angular.module('fingerink')
    .filter('signatureStatus', function ($translate) {
        return function (item) {
            switch (item) {
                case 'ERROR':
                    return $translate.instant('Error');
                case 'DRAFT':
                    return $translate.instant('Borrador');
                case 'WAITING':
                    return $translate.instant('Esperando a enviar');
                case 'PENDING':
                    return $translate.instant('Pendiente');
                case 'COMPLETED':
                    return $translate.instant('Completado');
                case 'EXPIRED':
                    return $translate.instant('Expirado');
                case 'REFUSED':
                    return $translate.instant('Rechazado');
            }
        };
    })

    .filter('signatureType', function ($translate) {
        return function (item) {
            switch (item) {
                case 'InPerson':
                    return $translate.instant('Firma Presencial');
                case 'Fast':
                    return $translate.instant('Firma en un click');
                case 'Advanced':
                    return $translate.instant('Firma avanzada');
                case 'Email':
                    return $translate.instant('Email Certificado');
                case 'SelfSigning':
                    return $translate.instant('Autofirma');
            }
        };
    })
    .filter('evidenceType', function ($translate) {
        return function (item) {
            switch (item) {
                case 'CREATE':
                    return $translate.instant("Firma Creada");

                case 'SEND':
                    return $translate.instant("Correo enviado");

                case 'OPENMAIL':
                    return $translate.instant("Email abierto");

                case 'OPENSIGN':
                    return $translate.instant("Documento abierto");

                case 'ACCEPT':
                    return $translate.instant("Términos y condiciones aceptados");

                case 'VIEW':
                    return $translate.instant("Documento visto");

                case 'SIGN':
                    return $translate.instant("Documento firmado");

                case 'COMPLETE':
                    return $translate.instant("Documento completado");

                case 'REJECT':
                    return $translate.instant("Documento rechazado");


            }
        };
    })
    .filter('notifications', function ($translate) {
        return function (item) {
            switch (item) {

                case 'DOCUMENT_SIGNED':
                    return $translate.instant("Documento firmado");

            }
        };
    });
